<template>
  <div class="details-box">
    <button class="close-button" @click="$emit('clear-selected-item')">
      <el-icon><Close /></el-icon>
    </button>
    <div class="title-box">
      <div class="title gray">
        {{ $t('pages.inventory.stocks.item_display.title') }}
      </div>
      <div class="barcode">
        {{ dash(item.barcode) }}
      </div>
    </div>
    <div class="name mb10">
      {{ item.name }}
    </div>
    <div class="current-stock gray">
      <template v-if="item.current_stock_from && item.current_stock_to">
        <div class="mb10">
          {{ $t('pages.inventory.stocks.item_display.current_stock_from') }}:
          {{ dash(item.current_stock_from) }}
        </div>
        <div>
          {{ $t('pages.inventory.stocks.item_display.current_stock_to') }}:
          {{ dash(item.current_stock_to) }}
        </div>
      </template>
      <template v-else>
        {{ $t('pages.inventory.stocks.item_display.current_stock') }}:
        {{ dash(item.current_stock) }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    dash(v) {
      return [null, undefined].includes(v) === false && `${v}`.length ? v : '--'
    }
  }
}
</script>

<style scoped>
.details-box {
  width: 100%;
  position: relative;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  text-align: center;
}

.title-box {
  margin: 0 10px 10px;
}

.title {
  text-transform: uppercase;
}

.barcode {
  font-size: 2.5rem;
}

.name {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.gray {
  color: gray;
}

.mb10 {
  margin-bottom: 10px;
}

.current-stock,
.order-qty {
  font-size: 1.2rem;
}

.close-button {
  position: absolute;
  top: 7px;
  right: 10px;
  color: lightgray;
  border: unset;
  font-size: 25px;
  cursor: pointer;
}

.close-button:hover {
  border: unset;
  color: lightgray;
  background-color: unset;
}
</style>
