<template>
  <div class="numpad-container">
    <section class="display">
      <el-input
        v-model="localValue"
        class="display-input"
        :disabled="disabled"
      />
    </section>
    <section class="numpad">
      <button
        class="key big-key backspace"
        :disabled="disabled"
        @click="backspace"
      >
        <el-icon><Back /></el-icon>
      </button>
      <button class="key clear" :disabled="disabled" @click="clear">C</button>
      <button
        v-for="number in numbers"
        :key="number"
        class="num key"
        :disabled="disabled"
        @click="setKey(number)"
      >
        {{ number }}
      </button>
      <button
        class="key big-key zero"
        :disabled="disabled"
        @click="setKey('0')"
      >
        0
      </button>
      <button class="key dot" :disabled="disabled" @click="setKey('.')">
        .
      </button>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: [Number, String],
      required: false,
      default: 0
    }
  },
  data() {
    return {
      numbers: ['7', '8', '9', '4', '5', '6', '1', '2', '3']
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue.toString()
      },
      set(v) {
        if (Number.isNaN(Number(v))) {
          this.$emit('update:modelValue', this.localValue)
        } else if (v.includes('.') && v.match(/\./g).length > 1) {
          this.$emit('update:modelValue', this.localValue)
        } else if (v.includes('.') && v.split('.')[1].length > 2) {
          this.$emit('update:modelValue', this.localValue)
        } else if (this.localValue === '0' && v === '.') {
          this.$emit('update:modelValue', `${this.localValue}${v}`)
        } else if (this.localValue.length === 1 && v === '') {
          this.$emit('update:modelValue', '0')
        } else if (
          this.localValue === '0' &&
          v.length > 1 &&
          v[0] === '0' &&
          v[1] !== '.'
        ) {
          this.$emit('update:modelValue', v.slice(1))
        } else {
          this.$emit('update:modelValue', v)
        }
      }
    }
  },
  methods: {
    setKey(v) {
      if (v === '.' && this.localValue.includes('.')) {
        return
      }
      if (
        this.localValue.includes('.') &&
        this.localValue.split('.')[1].length >= 2
      ) {
        return
      }
      if (this.localValue === '0' && v !== '.') {
        this.$emit('update:modelValue', v)
      } else {
        this.$emit('update:modelValue', `${this.localValue}${v}`)
      }
    },
    clear() {
      this.$emit('update:modelValue', '0')
    },
    backspace() {
      let newVal
      if (this.localValue === '0') {
        newVal = this.localValue
      } else if (this.localValue !== '0' && this.localValue.length === 1) {
        newVal = '0'
      } else {
        newVal = this.localValue.substring(0, this.localValue.length - 1)
      }

      this.$emit('update:modelValue', newVal)
    }
  }
}
</script>

<style scoped>
.numpad-container {
  width: 25rem;
  display: flex;
  flex-direction: column;
}

.display {
  border: none;
  width: 100%;
  margin-bottom: 15px;
}

.display-input :deep(.el-input__inner) {
  height: 6rem;
  text-align: center;
  font-size: 3rem;
}

.numpad {
  width: 100%;
}

.key {
  background: lightgrey;
  text-align: center;
  font-size: 1.1rem;
  border-radius: 4px;
  line-height: 40px;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  float: left;
  color: grey;
  width: 30.8%;
  margin: 5px;
}

button:focus {
  outline-color: #696969;
}

.big-key {
  width: 64%;
}

@media (max-width: 600px) {
  .numpad-container {
    width: 16rem;
  }

  .key {
    width: 29.4%;
  }

  .big-key {
    width: 62.7%;
  }
}
</style>
