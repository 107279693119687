<template>
  <div class="history-container">
    <div class="title bold">
      {{ title }}
    </div>
    <div class="history-box">
      <div v-for="item in historyList" :key="item.id" class="history-item">
        <div class="item-details">
          <span class="bold">{{ item.label }}</span>
          <span
            >{{ $t('pages.inventory.stocks.history.details.article_number') }}:
            {{ item.custom_id | empty }}</span
          >
          <span
            >{{ $t('pages.inventory.stocks.history.details.barcode') }}:
            {{ item.barcode | empty }}</span
          >
          <template v-if="item.location_from && item.location_to">
            <span
              >{{ $t('pages.inventory.stocks.history.details.location_from') }}:
              {{ item.location_from }}</span
            >
            <span
              >{{ $t('pages.inventory.stocks.history.details.location_to') }}:
              {{ item.location_to }}</span
            >
          </template>
          <span v-else-if="item.location"
            >{{ $t('pages.inventory.stocks.history.details.location') }}:
            {{ item.location }}</span
          >
        </div>
        <div class="item-qty">
          <span>{{ $formatNumber(item.qty, { dynamicPrecision: true }) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    empty(v) {
      return v || '--'
    }
  },
  props: {
    historyList: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped>
.history-container {
  width: 30rem;
  padding: 0 7rem;
}

.title {
  height: 2rem;
}

.history-box {
  border: 1px solid black;
  min-height: 6rem;
}

.history-item {
  min-height: 6rem;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
}

.history-item:nth-last-child(1) {
  border-bottom: unset;
}

.item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-qty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: bold;
}

@media (max-width: 1380px) {
  .history-container {
    padding: 0 2rem;
  }
}
</style>
