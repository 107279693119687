<template>
  <div class="remote-search-select__inner">
    <input
      ref="search-input"
      :class="['remote-search-select__input', { 'is-focus': isFocus }]"
      :placeholder="placeholder"
      :model-value="localValue"
      @update:modelValue="handleInput"
      @keydown="handleKey"
      @focus="handleInputFocus"
      @blur="handleInputBlur"
    />
    <div
      v-show="listOpen || loading"
      ref="list"
      class="remote-search-select__list"
      @mouseenter="handleBoxHover"
    >
      <template v-if="list.length">
        <ul ref="list-parent" class="list-parent">
          <li
            v-for="item in list"
            :key="item.id || item.value || item"
            class="remote-search-select__option"
            @click="pickItem(item.value || item.id || item)"
          >
            {{ item.label || item }}
          </li>
        </ul>
      </template>
      <div v-else class="remote-search-select__empty-list">
        {{ loading ? `${loadingText}...` : noDataText }}
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce'

export default {
  name: 'RemoteSearchSelectCustom',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    remoteMethod: {
      type: Function,
      required: false,
      default: () => {}
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    noDataText: {
      type: String,
      required: false,
      default: 'No data'
    },
    loadingText: {
      type: String,
      required: false,
      default: 'Loading'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      listOpen: false,
      listIndex: null,
      isFocus: false,
      localValue: ''
    }
  },
  computed: {
    optionsElementsList() {
      return this.$refs['list-parent'].childNodes
    }
  },
  beforeUnmount() {
    // clear the passed list so it won't persist for next mount.
    this.triggerRemoteMethod('')
  },
  methods: {
    handleBoxHover() {
      if (this.listIndex !== null) {
        const item = this.optionsElementsList.item(this.listIndex)
        if (item && item.classList) {
          item.classList.remove('selected')
        }
      }
      this.listIndex = null
    },
    triggerRemoteMethod: debounce(async function (value) {
      await this.remoteMethod(value)
    }, 300),
    handleInput(e) {
      this.localValue = e.target.value
      this.triggerRemoteMethod(e.target.value)
      this.listOpen = true
    },
    handleInputFocus() {
      this.isFocus = true
      if (this.list && this.list.length) {
        this.listOpen = true
      }
    },
    handleInputBlur() {
      setTimeout(() => {
        this.isFocus = false
        this.listOpen = false
      }, 150)
    },
    pickItem(item) {
      const value = item.value || item.id || item
      this.localValue = value
      this.$emit('update:modelValue', value)
      this.listOpen = false
    },
    handleKey(event) {
      const keyCode = event.keyCode
      if ([13, 38, 40].includes(keyCode) === false) return

      event.preventDefault()

      if (!this.listOpen && this.list && this.list.length) {
        this.listOpen = true
      }

      if (this.listOpen) {
        if (keyCode === 13) {
          // Enter/Return
          const item = this.list[this.listIndex]
          if (item) this.pickItem(item)
        }

        const prevIndex = this.listIndex

        this.listIndex = this.getListIndex(keyCode)

        // make item highlighted
        const currentItem = this.optionsElementsList.item(this.listIndex)
        if (this.listIndex !== null && currentItem && currentItem.classList) {
          currentItem.classList.add('selected')
        }
        if (prevIndex !== null && prevIndex !== this.listIndex) {
          const prevItem = this.optionsElementsList.item(prevIndex)
          if (prevItem && prevItem.classList) {
            prevItem.classList.remove('selected')
          }
        }

        // in a scrollable list the highlighted item should be visible
        this.$refs.list.scrollTop = currentItem.offsetTop - 235
      }
    },
    getListIndex(keyCode) {
      if (!this.list) return null
      if (keyCode === 40) {
        // KeyDown
        if (
          this.list.length === 1 ||
          this.listIndex === null ||
          this.listIndex === this.list.length - 1
        ) {
          return 0
        }
        return ++this.listIndex
      }
      if (keyCode === 38) {
        // KeyUp
        if (this.list.length === 1) return 0
        if (this.listIndex === null || this.listIndex < 1) {
          return this.list && this.list.length - 1
        }
        return --this.listIndex
      }
      return 0
    },
    focus() {
      this.$refs['search-input'].focus()
    }
  }
}
</script>

<style scoped>
.remote-search-select__inner {
  position: relative;
}

.remote-search-select__input {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: var(--label-text-color);
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.remote-search-select__list {
  z-index: 900;
  position: absolute;
  width: 100%;
  min-width: 200px;
  max-height: 274px;
  overflow: auto;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 5px 0;
}

.remote-search-select__option {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--label-text-color);
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}

.list-parent {
  padding: 0;
  margin: 0;
}

.remote-search-select__option:hover,
.remote-search-select__option.selected {
  background-color: #f5f7fa;
}

.remote-search-select__empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  color: var(--label-text-color);
}

.is-focus {
  border-color: #409eff;
}
</style>
