<template>
  <div class="panel">
    <div class="content">
      <div v-if="scanMode" class="scan-content">
        <div class="text">
          {{
            disabled
              ? $t('pages.inventory.stocks.scan_panel.scan.disabled')
              : $t('pages.inventory.stocks.scan_panel.scan.enabled')
          }}
        </div>
      </div>
      <div v-else class="search">
        <remote-search-select
          ref="search-select"
          v-model="input"
          :placeholder="
            $t('pages.inventory.stocks.scan_panel.select.placeholder')
          "
          :remote-method="fetchList"
          :list="sortedList"
          :loading="isLoadingItems"
          :loading-text="$t('common.loading.default')"
          :no-data-text="$t('components.th_datatable.no_data')"
        />
      </div>
    </div>
    <div :class="['icon', { hide: disabled }]">
      <el-icon @click="toggleScanMode"
        ><Search v-if="scanMode" /><CloseBold v-else
      /></el-icon>
    </div>
  </div>
</template>

<script>
import RemoteSearchSelect from '../rsc-custom'
import { sortLabels } from '../../helpers/index'

export default {
  components: {
    RemoteSearchSelect
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    },
    isLoadingItems: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      scanMode: true
    }
  },
  computed: {
    input: {
      get() {
        return this.modelValue
      },
      set(v) {
        const selectedItem = this.list.find((item) => item.id === v)
        this.$emit('update:modelValue', selectedItem)
      }
    },
    sortedList() {
      return [...this.list].sort(sortLabels)
    }
  },
  methods: {
    fetchList(v) {
      this.$emit('fetch-list', v)
    },
    toggleScanMode(v) {
      this.scanMode = !this.scanMode
      if (!this.scanMode) {
        this.$nextTick(() => this.$refs['search-select'].focus())
      }
    }
  }
}
</script>

<style scoped>
.panel {
  background-color: #d5f1b7;
  width: 100%;
  max-width: 40rem;
  height: 147px;
  margin: 16.5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
  border-radius: 3%;
}

.text {
  padding-left: 4rem;
  font-weight: 600;
}

.content {
  width: 80%;
}

.scan-content,
.search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.3rem;
}

.search {
  margin-left: 1rem;
}

.search :deep(.remote-search-select__input),
.search :deep(.remote-search-select__input:focus) {
  background-color: transparent;
  border-color: transparent;
  font-size: 1.2rem;
  outline: 0;
}

.search :deep(.remote-search-select__input:focus) {
  outline-color: #aac092;
  border: 1px solid #aac092;
  box-shadow: 0 0 4px #aac092;
}

.search :deep(.remote-search-select__input::placeholder) {
  color: gray;
  font-size: 1.1rem;
}

.icon {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon :deep(.el-button) {
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 1.3rem;
  border-radius: 50%;
  border-color: transparent;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon :deep(.el-button:hover) {
  background-color: rgba(0, 0, 0, 0.08);
}

.icon :deep(.el-icon-search),
.icon :deep(.el-icon-error) {
  font-size: 1.6rem;
  color: gray;
}

.hide {
  visibility: hidden;
}

.icon .el-button:focus {
  border: 1px solid #aac092;
  box-shadow: 0 0 4px #aac092;
}
</style>
