export function formatLabel(item) {
  const parseAttr = Object.values(item.attributes || {}).join(' - ')
  return parseAttr.length ? `${item.name} - ${parseAttr}` : item.name
}

export function sortLabels(a, b) {
  if (isValidLabel(a.label) && isValidLabel(b.label)) {
    return a.label.localeCompare(b.label)
  }
  // b is null, so if b has a value, we want a to come first -> return -1
  if (isValidLabel(a.label)) return -1
  // a is null, so if b has a value, we want b to come first -> return 1
  if (isValidLabel(b.label)) return 1
}

function isValidLabel(x) {
  return ![null, undefined].includes(x) && typeof x === 'string'
}

/**
 * Recursively find a location in a locations tree based on an id
 * Based on: https://stackoverflow.com/a/56505965/4640352
 * @param {object[]} locations
 * @param {string} id
 */
export function findById(locations, id) {
  for (const location of locations) {
    if (location.id === id) return location

    if (Array.isArray(location.shelves)) {
      const shelfLocation = findById(location.shelves, id)
      if (shelfLocation) return shelfLocation
    }
  }
  return undefined
}
