<template>
  <div class="select-container">
    <el-select
      :id="id"
      ref="multilevel-select"
      v-model="localValue"
      v-cancel-read-only
      class="select"
      filterable
      :disabled="disabled"
      :filter-method="findMultilevelItem"
      :no-data-text="$t('components.th_datatable.no_data')"
      :placeholder="placeholderText"
    >
      <div v-for="item in computedList" :key="item.id">
        <div v-if="item.shelves && item.shelves.length">
          <el-option class="parent-item" :label="item.name" :value="item.id" />
          <el-option
            v-for="shelf in item.shelves"
            :key="shelf.id"
            class="child-item"
            :label="shelf.name"
            :value="shelf.id"
          />
        </div>
        <div v-else>
          <el-option class="parent-item" :label="item.name" :value="item.id" />
        </div>
      </div>
    </el-select>
  </div>
</template>

<script>
import compare from 'just-compare'
import typeOf from 'just-typeof'
import cloneDeep from 'clone-deep'

function findItem(v) {
  return (item) =>
    item &&
    typeOf(item.name) === 'string' &&
    item.name.toLowerCase().includes(v.toLowerCase())
}

function filterMultiLevelList(item, v) {
  const findItemByValue = findItem(v)
  if (item.shelves) {
    item.shelves = item.shelves.filter(findItemByValue)
    return item.shelves.length || findItemByValue(item)
  }
  return findItemByValue(item)
}

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    },
    placeholderText: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      localList: []
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$nextTick(() => this.$refs['multilevel-select'].blur())
        this.$emit('update:modelValue', v)
      }
    },
    computedList: {
      get() {
        return this.localList
      },
      set(v) {
        this.localList = v
      }
    }
  },
  watch: {
    list(newList, oldList) {
      if (!compare(newList, oldList)) {
        this.computedList = newList
      }
    }
  },
  mounted() {
    this.computedList = this.list
  },
  methods: {
    findMultilevelItem(v) {
      this.computedList = v
        ? cloneDeep(this.list).filter((item) => filterMultiLevelList(item, v))
        : this.list
    }
  }
}
</script>

<style scoped>
.select-container,
.select {
  width: 100%;
}

.parent-item {
  font-weight: 600;
}

.child-item {
  padding-left: 3rem;
  font-style: var(--child-item-style);
}
</style>
